import React, { Component } from "react"
import Layout from "components/layout"
import SEO from "components/seo"

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          meta={[
            {
              name: "description",
              content:
                "The Surrey Health and Beauty Spa based in Westcroft Leisure Centre, Carshalton",
            },
            { name: "keywords", content: "surrey, health, beauty, spa" },
          ]}
        />

        <div className="hero flex px2">
          <div className="m-auto">
            <div className="hero__copy white mb2">
              <h1 className="h1 light mt0 mb1 line-height-1">
                Look and feel incredible
              </h1>
              <h1 className="h3 light regular my0">
                Sign up to receive £5 discount on your first visit
              </h1>
            </div>

            <form
              className="form"
              action="http://surreyhealthandbeautyspa.createsend.com/t/i/s/iltiku/"
              method="post"
              id="subForm"
            >
              <div className="fieldset flex flex-wrap mxn1 mb1">
                <div className="border-box col-12 sm-col-6 px1">
                  <input
                    className="input col-12"
                    type="text"
                    placeholder="Your name"
                    id="fieldName"
                    name="cm-name"
                  />
                </div>
                <div className="border-box col-12 sm-col-6 px1">
                  <input
                    className="input col-12"
                    type="email"
                    placeholder="Your email address"
                    id="fieldEmail"
                    name="cm-iltiku-iltiku"
                    required
                  />
                </div>
              </div>
              <div className="form__submit">
                <input className="submit" type="submit" value="Get discount" />
              </div>
            </form>
          </div>
        </div>

        <div className="max-width-2 mx-auto px2 py3">
          <p className="h3 mt0">
            Welcome to the Surrey Health and Beauty Spa. We are a Day Spa at
            Westcroft Leisure Centre in Carshalton and aim to bring luxury
            products and quality treatments that look after, treat and maintain
            your holistic health.
          </p>

          <p>
            We provide treatments in our 4 treatment rooms, relaxation and
            waiting area. Patients can select from a wide range of treatments
            that do truly cover the whole body both outside and in. We offer
            indulgent and results-driven treatments including Elemis spa
            treatments, massage and facials as well as your maintenance
            treatments such as waxing, manicures and pedicures.
          </p>

          <p>
            To be holistic, we must look at every aspect of our lives and take
            advantage of different professions and knowledge to improve our
            whole body. This is why at Surrey Health and Beauty Spa we also
            provide reflexology, nutritionists and life coaching. This really
            sets us apart from many other spas.
          </p>

          <p>
            As we all know massage is an essential key to help us relax and
            energised which is why we use a comprehensive range to treatments
            and times for our clients to choose from. We offer deep tissue
            massage, Swedish massage, aromatherapy massage, sports massage and
            Indian head massage. All of our massages are available in 30 and 60
            minute slots with some available for 90 minutes.
          </p>

          <p>
            If the treatment is not for you can simply call our clinic on 0203
            773 3452, or email us on info@surreyhealthandbeautyspa.com to book
            in your loved ones for the desired treatments which can be paid for
            over the phone or if you come into the spa you can receive a voucher
            to give as a gift.
          </p>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
